import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Typography,
  Card,
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import "../../styles/style.css";

import { useMutation, useQuery } from "@tanstack/react-query";
import { Instance } from "../../../config/axiosConfig";
import { CourseApi } from "../../../data/apiUrls";
import AppContext from "../../../context/appContext";
import PayContext from "../../../context/payContext";

import { ArrowRight2, ProfileDelete, Trash } from "iconsax-react";
import { submitPayment } from "../../../data/SubmitPayment";
import ApplyCoupon from "./ApplyCoupon";

import { PaymentApi } from "../../../data/apiUrls";
import LoadingSpinner from "../Loader/loader";
import AmountContext from "../../../context/amountContext";

const queryParams = new URLSearchParams(window.location.search);
const userId = queryParams.get("userId") ?? 0;
const courseId = queryParams.getAll("courseId") ?? 0;
const type = queryParams.get("from");
const planType = queryParams.get("plan");

// const CustomSelect = styled(Select)(({ theme }) => ({
//   borderRadius: "12px",
//   "& .MuiOutlinedInput-notchedOutline": {
//     borderColor: "red", // Change border color here
//   },
//   "&:hover .MuiOutlinedInput-notchedOutline": {
//     borderColor: "blue", // Change border color on hover
//   },
//   "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//     borderColor: "green", // Change border color on focus
//   },
// }));

const ProductTable = ({
  setShowPaymentMethods,
  // discount,
  couponCode,
  // setDiscount,
  setCouponCode,
}) => {
  const { discountedAmount, updateDiscountedAmount } =
    useContext(AmountContext);
  const { sharedData, updateSharedData } = useContext(AppContext);
  const { paymentInstrumentData } = useContext(PayContext);
  const [payWithInstrument, setPayWithInstrument] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [discountAmt, setDiscountAmt] = useState(0);
  const [currency, setCurrency] = useState(null);
  const [courseData, setCourseData] = useState([]);
  const [sum, setSum] = useState(0);
  const [selectedSum, setSelectedsum] = useState(0);
  const [selectProductId, setSelectProductId] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [discountedTotal, setDiscountedTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [copuonDiscountPercentage, setCopuonDiscountPercentage] = useState(0);
  const [couponType, setCouponType] = useState("");

  // const [discountedTotal, setDiscountedTotal] = useState(getTotalData() ?? 0);

  // const getTotalData = () => {
  //   return parseFloat(sum) - parseFloat(discount);
  // };

  useEffect(() => {
    setPayWithInstrument(paymentInstrumentData);
  }, [paymentInstrumentData]);

  // useEffect(() => {
  //   updateDiscountedAmount(getTotalSum());
  // }, [discount, sum]);

  useEffect(() => {
    const userPayload = {
      amount: discountedTotal === 0 ? sum : Math.ceil(discountedTotal),
      coupon: couponCode,
      from: type,
      user_id: userId,
      package_id: selectedProducts.length > 0 ? courseId : selectProductId,
      planType: planType,
      course_data: courseData,
    };
    updateSharedData(userPayload);
  }, [discountedTotal]);
  // useEffect(() => {
  //   setDiscountedTotal(getTotalData());
  // }, [discount, orderSummary]);

  const getTotalSum = () => {
    const discountamount = sum - discountAmt;
    return discountamount;
  };

  //get data from backend
  const { data, isLoading, isError, error } = useQuery(
    ["getData"],
    async () => {
      const response = await Instance.get(
        `${CourseApi.getCourseList}?packageId=${courseId}&from=${type}&planType=${planType}`
      );
      const currencyTag =
        response.data.length > 0 ? response.data[0].currency : "Rs.";

      const selectedProducts = response?.data?.reduce((total, product) => {
        const defaultProducts = product.package_list.find(
          (pkg) => pkg.default_status === "1"
        );

        if (defaultProducts) {
          total.push({
            course_id: product?.id,
            productPrice: defaultProducts?.price,
            productValidity: defaultProducts?.validity,
            discountedAmount: defaultProducts?.discounted_price,
            discount_flag: defaultProducts?.discount_flag,
            productId: defaultProducts?.id,
          });
        }
        return total;
      }, []);

      const totalDiscount = response?.data?.reduce((total, crr) => {
        let totalDiscount = crr?.discountedAmount;
        return total + parseInt(totalDiscount);
      }, 0);

      setDiscountAmt(totalDiscount);
      setCurrency(currencyTag);
      setSelectedRow(selectedProducts);
      setSelectedProducts(selectedProducts);

      return response?.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  // const {data, isLoading} = useQuery({
  //   queryKey: ["getData"],
  //   queryFn: async () => {
  //     const response = await Instance.get(
  //       `${CourseApi.getCourseList}?packageId=${courseId}&from=${type}&planType=${planType}`
  //     )
  // }})
console.log(data)
  useEffect(() => {
    if (data) {
      const totalSum = selectedProducts?.reduce((total, current) => {
        let totalPrice =
          current?.discount_flag === "1"
            ? current?.discountedAmount
            : current?.productPrice;

        return total + parseInt(totalPrice);
      }, 0);

      const selectedProductIds = selectedProducts?.map((curr) => {
        let id = curr.productId;
        return id;
      });

      const courseData = selectedProducts?.map((item) => ({
        course_id: item.course_id,
        course_package_id: item.productId,
        validity: item.productValidity,
      }));

      setCourseData(courseData);
      setSum(totalSum);
      const couponDiscountAmount =
        couponType === "percentage"
          ? totalSum * copuonDiscountPercentage
          : couponType === "amount"
          ? copuonDiscountPercentage
          : 0;
      setDiscount(Math.floor(couponDiscountAmount));
      updateDiscountedAmount(totalSum);
      setSelectProductId(selectedProductIds);
    }
  }, [selectedProducts]);
  useEffect(() => {
    setDiscountedTotal(sum !== 0 ? sum - discount : sum);
  }, [discountAmt, sum, selectedProducts, discount]);
  if (isLoading) {
    return (
      <div className="overlay">
        <LoadingSpinner />
      </div>
    );
  }
  // const handleOnchange = (productId) => {
  //   const isSelected = selectedProducts.includes(productId);
  //   const newSelectedProducts = isSelected
  //     ? selectedProducts.filter((id) => id !== productId)
  //     : [...selectedProducts, productId];

  //   const newTotalSum = orderSummary
  //     .filter((product) => newSelectedProducts.includes(product.id))
  //     .reduce((total, product) => total + parseFloat(product.price), 0);

  //   const unmatchedProductIds = orderSummary
  //     .map((product) => product.id) // Extract all product IDs
  //     .filter((productId) => !newSelectedProducts.includes(productId));

  //   const newTotalPrice = orderSummary
  //     .filter((product) => !newSelectedProducts.includes(product.id))
  //     .reduce((total, products) => total + parseFloat(products.price), 0);

  //   setSelectedProductId(unmatchedProductIds);
  //   setSelectedProducts(newSelectedProducts);
  //   setSelectedsum(newTotalSum);
  //   setSum(newTotalPrice);
  // };

  const handleOnchange = (productId, event) => {
    const checked = event.target.checked;

    if (!checked) {
      const product = selectedProducts.filter(
        (item) => item.course_id !== productId
      );
      setSelectedProducts(product);
    } else {
      const product = selectedRow.find((item) => item.course_id === productId);
      setSelectedProducts((prevSelectedProducts) => [
        ...prevSelectedProducts,
        product,
      ]);
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    const updatedSelectedProducts = selectedProducts?.map((product) => {
      if (product.course_id === value.course_id) {
        return {
          ...product,
          productPrice: value?.price,
          productValidity: value?.validity,
          discountedAmount: value?.discounted_price,
          discount_flag: value?.discount_flag,
          productId: value?.id,
        };
      }
      return product;
    });
    setSelectedRow(updatedSelectedProducts);
    setSelectedProducts(updatedSelectedProducts);
  };

  return (
    <Container
    >
      <Card
        className="product-table"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
          // padding: "18px",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            display: "flex",
            alignItems: "left",
            justifyContent: "flex-start",
            textAlign: "left",
          }}
        >
          Order Summary
        </Typography>
        <Box
          sx={{
            width: {
              // xs: 330,
              // sm: 460,
              // md: 460,
              lg: 460,
              xl: 460,
            },
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <Box sx={{ maxHeight: "40vh", overflowY: "scroll" }}>
            {Array.isArray(data) && data.map((product, index) => {
              const defaultPackage = product.package_list.find(
                (item) => item.default_status === "1"
              );
              const defaultValue = defaultPackage ? defaultPackage : "";
              return (
                <Card
                  key={product.id}
                  sx={{
                    width: {
                      // xs: 342,
                      // sm: 460,
                      // md: 460,
                      lg: 460,
                      xl: 460,
                    },
                    // margin: "20px",
                    // display: "flex",
                  }}
                >
                  <Box display={"flex"} gap={"20px"}>
                    {/* Product Image */}
                    <Box>
                      <img
                        width={"100"}
                        height={"auto"}
                        src={product.image}
                        alt={product.name}
                        style={{
                          borderRadius: "10%",
                        }}
                      />
                    </Box>

                    <Box display={"grid"} width={"100%"}>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Typography variant="h6">{product.title}</Typography>
                        <Button
                          size="medium"
                          startIcon={<Trash size={"28"} color="#8B0000" />}
                        ></Button>
                      </Box>
                      <Box
                        justifyContent={"space-between"}
                        sx={{ display: { xs: "none", md: "flex" } }}
                      >
                        <FormControl
                          key={product.id}
                          sx={{
                            m: 1,
                            minWidth: 120,
                          }}
                          size="small"
                        >
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            defaultValue={defaultValue}
                            onChange={handleChange}
                            sx={{ borderRadius: "12px" }}
                          >
                            {product.package_list.map((product) => {
                              const totalPrice =
                                product?.discount_flag === "1"
                                  ? product.discounted_price
                                  : product.price;
                              const discountAmount =
                                product?.discount_flag === "1" ? true : false;
                              return (
                                <MenuItem value={product} key={product.id}>
                                  {product.default_status === "1" ? (
                                    <Box
                                      display={"flex"}
                                      width={"100%"}
                                      justifyContent={"space-between"}
                                      color={"#018749"}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "5px",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span>
                                          {product.validity
                                            ? product.validity === "N/A"
                                              ? "12months"
                                              : product.validity + " days"
                                            : "12 months"}
                                        </span>
                                        <span
                                          style={{
                                            fontSize: "10px",
                                            color: "#018749",
                                          }}
                                        >
                                          {product.discount_precentage
                                            ? `(${product.discount_precentage}% off)`
                                            : ""}
                                        </span>
                                      </div>
                                      <div>
                                        {currency}
                                        {totalPrice}
                                      </div>
                                    </Box>
                                  ) : (
                                    <Box
                                      display={"flex"}
                                      width={"100%"}
                                      justifyContent={"space-between"}
                                      color={"#8B0000"}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "5px",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span>
                                          {product.validity
                                            ? product.validity === "N/A"
                                              ? "12months"
                                              : product.validity + " days"
                                            : "12 months"}
                                        </span>
                                        <span
                                          style={{
                                            fontSize: "10px",
                                            color: "#8B0000",
                                          }}
                                        >
                                          {product.discount_flag === "1"
                                            ? `( ${product.discount_precentage}% off )`
                                            : ""}
                                        </span>
                                      </div>
                                      <div>
                                        {currency}
                                        {totalPrice}
                                      </div>
                                    </Box>
                                  )}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <div></div>
                        {/* <Checkbox
                          defaultChecked={true}
                          size="medium"
                          sx={{ color: "#8B0000" }}
                          onChange={(e) => handleOnchange(product.id, e)}
                        /> */}
                      </Box>
                    </Box>
                    {/* <Typography variant="span">
                      {product.currency}{" "}
                      {product.price == "Free" || product.price == ""
                        ? 0
                        : product.price}
                    </Typography> */}

                    {/* Select for mobile version */}
                  </Box>
                  <Box
                    justifyContent={"space-between"}
                    sx={{ display: { xs: "flex", md: "none" } }}
                  >
                    <FormControl
                      key={product.id}
                      sx={{
                        m: 1,
                        minWidth: "70%",
                      }}
                      size="small"
                    >
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        defaultValue={defaultValue}
                        onChange={handleChange}
                        sx={{ borderRadius: "12px" }}
                      >
                        {product.package_list.map((product) => {
                          const totalPrice =
                            product?.discount_flag === "1"
                              ? product.discounted_price
                              : product.price;
                          const discountAmount =
                            product?.discount_flag === "1" ? true : false;
                          return (
                            <MenuItem value={product} key={product.id}>
                              {product.default_status === "1" ? (
                                <Box
                                  display={"flex"}
                                  width={"100%"}
                                  justifyContent={"space-between"}
                                  color={"#018749"}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "5px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span>
                                      {product.validity
                                        ? product.validity === "N/A"
                                          ? "12months"
                                          : product.validity + " days"
                                        : "12 months"}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: "10px",
                                        color: "#018749",
                                      }}
                                    >
                                      {product.discount_flag === "1"
                                        ? `( ${product.discount_precentage}% off )`
                                        : ""}
                                    </span>
                                  </div>
                                  <div>
                                    {currency}
                                    {totalPrice}
                                  </div>
                                </Box>
                              ) : (
                                <Box
                                  display={"flex"}
                                  width={"100%"}
                                  justifyContent={"space-between"}
                                  color={"#8B0000"}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "5px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span>
                                      {product.validity
                                        ? product.validity === "N/A"
                                          ? "12months"
                                          : product.validity + " days"
                                        : "12 months"}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: "10px",
                                        color: "#8B0000",
                                      }}
                                    >
                                      {product.discount_flag
                                        ? `(${product.discount_flag}%  off)`
                                        : ""}
                                    </span>
                                  </div>
                                  <div>
                                    {currency}
                                    {totalPrice}
                                  </div>
                                </Box>
                              )}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>

                    <Checkbox
                      defaultChecked={true}
                      size="medium"
                      sx={{ color: "#8B0000" }}
                      onChange={(e) => handleOnchange(product.id, e)}
                    />
                  </Box>
                </Card>
              );
            })}
          </Box>
          <ApplyCoupon
            setDiscount={setDiscount}
            setCouponCode={setCouponCode}
            discountedPrice={Math.floor(discount)}
            discountAmount={sum !== 0 ? sum - Math.floor(discount) : 0}
            totalPrice={sum}
            setCopuonDiscountPercentage={setCopuonDiscountPercentage}
            setCouponType={setCouponType}
          />

          <Box className=" desktop-view-container">
            <Button
              variant="contained"
              sx={{
                width: {
                  xs: 312,
                  md: 460,
                  lg: 460,
                  xl: 500,
                },
              }}
              onClick={() => {
                submitPayment(payWithInstrument, sharedData);
                setLoading(true);
              }}
              disabled={
                payWithInstrument?.gateway?.length === undefined ||
                getTotalSum() <= 0
              }
            >
              Pay
              <ArrowRight2 />
            </Button>
            {loading && (
              <div
                style={{
                  position: "fixed",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.2)", // Set the background color to semi-transparent black
                  zIndex: "9999",
                }}
              >
                <LoadingSpinner />
              </div>
            )}
          </Box>

          <Box className="mobile-button-container">
            <Button
              variant="contained"
              onClick={(e) => {
                setShowPaymentMethods(true);
              }}
              sx={{
                bottom: "0px",
                padddingBottom: "20px",
                width: {
                  xs: 312,
                  md: 460,
                  lg: 460,
                  xl: 460,
                },
              }}
              disabled={getTotalSum() <= 0}
            >
              Next
              <ArrowRight2 />
            </Button>
          </Box>
        </Box>
      </Card>
    </Container>
  );
};

export default ProductTable;
