import {
  Box,
  Card,
  Typography,
  FormControl,
  IconButton,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import React, { useState } from "react";
import "../../styles/style.css";

import { Instance } from "../../../config/axiosConfig";
import { Payment, PaymentApi } from "../../../data/apiUrls";
import LoadingSpinner from "../Loader/loader";

const ApplyCoupon = ({
  setDiscount,
  discountedPrice,
  discountAmount,
  setCouponCode,
  totalPrice,
  setCopuonDiscountPercentage,
  setCouponType,
}) => {
  const [coupon, setCoupon] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const handleCouponCodeChange = (event) => {
    const enteredCoupon = event.target.value;
    setCoupon(enteredCoupon);
    if (enteredCoupon.length < 2) {
      setCompleted(false);
      setMessage(null);
      setDiscount(0);
      setCouponCode();
    }
  };

  // onclick of apply coupon button
  // request to backend i.e. /bridge/coupon
  // if response is 200 then subtract the amount from total amount
  // else say invalid coupon code

  const applyCoupon = async (e) => {
    if (e.target.value.length < 1) {
      return;
    }
    e.preventDefault();
    try {
      setLoading(true);
      setCompleted(false);

      const response = await Instance.post(PaymentApi.applyCoupon, {
        couponCode: coupon,
      });

      if (response.status === 200) {
        if (response.data?.discount?.discountType === "percentage") {
          setCouponType("percentage");
          setDiscount(
            totalPrice *
              (Math.floor(
                response.data?.discount?.discountAmount.replace("%", "")
              ) /
                100)
          );
          setCopuonDiscountPercentage(
            Math.floor(
              response.data?.discount?.discountAmount.replace("%", "")
            ) / 100
          );
        } else {
          setCouponType("amount");
          setDiscount(Math.floor(response.data?.discount?.discountAmount));
          setCopuonDiscountPercentage(
            Math.floor(response.data?.discount?.discountAmount)
          );
        }
        setCouponCode(coupon);
        setMessage("Coupon applied successfully!");
        setCompleted(true);
      }
    } catch (error) {
      setDiscount(Math.floor(0));
      setMessage("Invalid coupon code");
    } finally {
      setLoading(false); // Set loading back to false after the operation is done
    }
  };

  const showInputAdornment = () => {
    if (loading) {
      return <LoadingSpinner />;
    } else if (completed) {
      return <>✔️</>;
    } else {
      return (
        <IconButton
          sx={{
            fontSize: "15px",
            borderRadius: "6px",
            marginRight: "-5px",
            color: "white",
            backgroundColor: "gray",
            "&:hover": {
              color: "gray",
            },
          }}
          onClick={(event) => {
            applyCoupon(event);
          }}
          disabled={loading}
          edge="end"
        >
          Apply
        </IconButton>
      );
    }
  };

  return (
    <>
      <FormControl
        sx={{
          border: "2px dashed #8B0000",
          width: {
            lg: 460,
            xl: 460,
          },
          borderRadius: "10px",
          color: "#8B0000",
        }}
      >
        <OutlinedInput
          placeholder="Enter Your Coupon Code"
          onChange={handleCouponCodeChange}
          onBlur={(event) => {
            applyCoupon(event);
          }}
          disabled={loading}
          endAdornment={
            <InputAdornment position="end">
              {showInputAdornment()}
            </InputAdornment>
          }
        />
      </FormControl>
      {message && (
        <Typography
          sx={{
            marginTop: "10px",
            color: message.includes("successfully") ? "green" : "#8B0000",
          }}
        >
          {message}
        </Typography>
      )}

      <Card
        className="order-summary"
        sx={{
          width: {
            xs: 280,
            // sm: 460,
            // md: 460,
            lg: 460,
            xl: 460,
          },
          boxShadow: "none",
        }}
      >
        <Typography variant="span" fontWeight={"bold"}>
          Order Summary
        </Typography>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          sx={{ marginTop: "10px" }}
        >
          <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
            <Box display={"flex"} flexDirection={"column"} gap={"6px"}>
              <Typography variant="span">Order Price</Typography>
              <Typography variant="span">Coupon Discount</Typography>
            </Box>
            <Typography variant="bold"> Total Price</Typography>
          </Box>

          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={"10px"}
            alignItems={"right"}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={"6px"}
              justifyContent={"flex-end"}
            >
              <Typography variant="span" sx={{ textAlign: "end" }}>
                {" "}
                {totalPrice}
              </Typography>
              <Typography
                variant="span"
                sx={{ color: "#216DBB", textAlign: "end" }}
              >
                {Math.floor(discountedPrice)}
              </Typography>
            </Box>
            <Typography variant="span" fontWeight={"bold"} color={"#8B0000"}>
              {discountAmount}
            </Typography>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default ApplyCoupon;
